import { ApplicationManager } from 'van-beek-framework/core';
import { SortedList } from 'van-beek-framework/components/lists';
import ConfiguratorNetworkManager from 'core/ConfiguratorNetworkManager';
import { Project } from 'entities/project';
import { Configuration } from 'entities/configuration';

export default class ConfiguratorManager extends ApplicationManager {
    _projects = [];
    _configurations = [];

    static _LocalStorageKey = "machineconfigurator-projects";

    activeProjects = new SortedList();
    quotedProjects = new SortedList();
    deletedProjects = new SortedList();

    _projectCompareFunc = function (a, b) {
        var textA = a.name.toLowerCase(),
            textB = b.name.toLowerCase(),
            idA = a.id,
            idB = b.id;

        if (textA < textB) {
            return -1;
        } else if (textA > textB) {
            return 1;
        } else if (idA < idB) {
            return -1;
        } else if (idA > idB) {
            return 1;
        } else {
            return 0;
        }
    };

    constructor() {
        super();
        
        this.activeProjects.comparator = this._projectCompareFunc;
        this.quotedProjects.comparator = this._projectCompareFunc;
        this.deletedProjects.comparator = this._projectCompareFunc;

        var stringified = localStorage.getItem(this.constructor._LocalStorageKey);
        if (stringified != null && stringified.length > 0) {
            try {
                var projectsJson = JSON.parse(stringified);
                this._fromJson(projectsJson);
            } catch (e) {
                Logger.warn("[ProjectManager] LocalStorage data was corrupted and has been cleared to prevent further issues.");
                localStorage.removeItem(this.constructor._LocalStorageKey);
            }
        }

        this._setNetworkManager(ConfiguratorNetworkManager);

        $(window).bind("storage", (e) => {
            if (e.originalEvent.key === this.constructor._LocalStorageKey) {
                var projectsJson = JSON.parse(e.originalEvent.newValue);
                this._fromJson(projectsJson);
            }
        });
    }

    _setNetworkManager(networkManager) {
        networkManager.fetchProjectsEvent.subscribe((manager, projectsData) => {
            this._fromJson(projectsData);
            this._writeToLocalStorage();
        });

        networkManager.fetchProjectEvent.subscribe((manager, projectData) => {
            this._updateProject(projectData);
            this._writeToLocalStorage();
        });

        networkManager.addProjectEvent.subscribe((manager, projectData) => {
            var project = Project.Decode(projectData);
            this.addProject(project);
            this._writeToLocalStorage();
        });

        networkManager.duplicateProjectEvent.subscribe((manager, projectData) => {
            var project = Project.Decode(projectData);
            this.addProject(project);
            this._writeToLocalStorage();
        });

        networkManager.reviseProjectEvent.subscribe((manager, projectId, projectData) => {
            this.getProject(projectId).decode(projectData);
            this._writeToLocalStorage();
        });

        networkManager.requestQuotationEvent.subscribe((manager, projectId, projectData) => {
            this.getProject(projectId).decode(projectData);
            this._writeToLocalStorage();
        });

        networkManager.renameProjectEvent.subscribe((manager, projectId, newName) => {
            var project = this.getProject(projectId);
            project.name = newName;

            this._getRelevantList(project).sort();
            this._writeToLocalStorage();
        });

        networkManager.deleteProjectEvent.subscribe((manager, projectId) => {
            this.getProject(projectId).isDeleted = true;
            this._writeToLocalStorage();
        });

        networkManager.renameConfigurationEvent.subscribe((manager, configurationId, newName) => {
            this.getConfiguration(configurationId).name = newName;
            this._writeToLocalStorage();
        });

        networkManager.deleteConfigurationEvent.subscribe((manager, configurationId) => {
            this.deleteConfiguration(configurationId);
            this._writeToLocalStorage();
        });

        networkManager.updateConfigurationQuantityEvent.subscribe((manager, configurationId, quantity) => {
            this.getConfiguration(configurationId).quantity = quantity;
            this._writeToLocalStorage();
        });

        networkManager.updateConfigurationCommentEvent.subscribe((manager, configurationId, newComment) => {
            this.getConfiguration(configurationId).comment = newComment;
            this._writeToLocalStorage();
        });

        networkManager.updateConfigurationAdditionalSpecificationsEvent.subscribe((_, configurationId, newAdditionalSpecifications) => {
            this.getConfiguration(configurationId).additionalSpecifications = newAdditionalSpecifications;
            this._writeToLocalStorage();
        });

        networkManager.updateProjectCommentEvent.subscribe((manager, projectId, newComment) => {
            this.getProject(projectId).comment = newComment;
            this._writeToLocalStorage();
        });

        networkManager.duplicateConfigurationEvent.subscribe((manager, configurationId, configurationData) => {
            var project = this.getConfiguration(configurationId).project;

            if (project != null) {
                var configuration = Configuration.Decode(configurationData);
                project.items.push(configuration);

                this._writeToLocalStorage();
            }
        });
    }

    _getRelevantList(project) {
        if (project == null) {
            return null;
        }

        if (project.isDeleted) {
            return this.deletedProjects;
        } else if (project.hasActiveQuotation) {
            return this.quotedProjects;
        } else {
            return this.activeProjects;
        }
    }

    _updateProject(projectJson) {
        var project = Project.Decode(projectJson);
        var existingProject = this.getProject(project.id);

        if (existingProject != null) {
            existingProject.decode(projectJson);
            return existingProject;
        } else {
            this.addProject(project);
            return project;
        }
    }

    _fromJson(projectsJson) {
        var projectsInJson = [];

        for (var projectJson of projectsJson) {
            var project = this._updateProject(projectJson);
            if (project != null) {
                projectsInJson.push(project);
            }
        }

        for (var i = this._projects.length - 1; i >= 0; i--) {
            var project = this._projects[i];
            var foundInJson = false;

            for (var j = 0; j < projectsInJson.length; j++) {
                var projectInJson = projectsInJson[j];

                if (project.id === projectInJson.id) {
                    foundInJson = true;
                    projectsInJson.splice(j, 1);
                    break;
                }
            }

            if (!foundInJson) {
                this.deleteProject(project.id);
            }
        }
    }

    _toJson() {
        var data = [];

        for (var project of this._projects) {
            var projectData = project.encode();
            data.push(projectData);
        }

        return data;
    }

    _writeToLocalStorage() {
        var json = this._toJson();
        var stringified = JSON.stringify(json);
        localStorage.setItem(this.constructor._LocalStorageKey, stringified);
    }

    onProjectStateChanged(project) {
        var lists = [this.activeProjects, this.quotedProjects, this.deletedProjects];
        var newList = this._getRelevantList(project);

        if (newList == null || newList.hasItem(project)) {
            return;
        }

        lists.splice(lists.indexOf(newList), 1);

        for (var list of lists) {
            list.remove(project);
        }

        newList.push(project);
    }

    _indexOf(projectId) {
        for (var i = 0; i < this._projects.length; i++) {
            if (this._projects[i].id === projectId) {
                return i;
            }
        }

        return -1;
    }

    getConfiguration(id, isUuid = false) {
        for (var project of this._projects) {
            for (var configuration of project.configurations) {
                if (isUuid && configuration.uuid === id) {
                    return configuration;
                } else if (!isUuid && configuration.id === id) {
                    return configuration;
                }
            }
        }

        return null;
    }

    //Local requests
    getProject(id) {
        var index = this._indexOf(id);
        if (index <= -1) {
            return null;
        }

        return this._projects[index];
    }

    hasProject(id) {
        return this._indexOf(id) >= 0;
    }

    addProject(project) {
        if (project == null || this.hasProject(project.id)) {
            return;
        }

        this._projects.push(project);

        project.managerDeletionSubscription = project.deletionStateChangedEvent.subscribe(this.onProjectStateChanged.bind(this));
        project.managerQuotationSubscription = project.quotationStateChangedEvent.subscribe(this.onProjectStateChanged.bind(this));

        this._getRelevantList(project).push(project);
    }

    deleteProject(projectId) {
        var index = this._indexOf(projectId);
        if (index <= -1) {
            return;
        }

        var project = this._projects[index];

        project.managerDeletionSubscription.unsubscribe();
        project.managerQuotationSubscription.unsubscribe();

        this._projects.splice(index, 1);
        this._getRelevantList(project).remove(project);
    }

    deleteConfiguration(id) {
        var configuration = this.getConfiguration(id);
        if (configuration == null) {
            return;
        }

        var project = configuration.project;

        if (project != null) {
            project.items.remove(configuration);
        }
    }
}
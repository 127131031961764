import "./LoadingPopup.css";
import BasePopup from './BasePopup';
import PopupIcon from './PopupIcon';

export default class LoadingPopup extends BasePopup {
    static show(opts) {
        if (opts == null) opts = {};

        this.hide();
        this._shared = new LoadingPopup(opts.title, opts.message, opts);
        return this._shared.show();
    }

    static hide() {
        if (this._shared == null) {
            return;
        }

        this._shared.hide();
        this._shared = null;
    }

    static _shared = null;

    constructor(title, message = "popup-defaults.loading-message".localized, opts) {
        opts.wrapperInteractionDismisses = true;
        super(title, message, PopupIcon.loading(), [], opts);
    }

    show() {
        let promise = super.show();
        this._rejectFunction = this._resolveFunction;
        return promise;
    }
    
    hide() {
        this._resolveFunction();
        super.hide();
    }

    _populate() {
        super._populate();
        this.$popup.addClass("vbf-popup--loading");
    }

    _reject() {
        this.hide();
    }

    //Remove keyboard bindings, popup cannot be dismissed
    _setupEvents() {}
    _bindEventsOnShow() {}
    _unbindEventsOnHide() {}
}
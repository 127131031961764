import { BaseView } from '../../abstract';

export default class ListView extends BaseView {

    _delegate = null;
    _itemController = null;
    _itemViews = [];

    get views() {
        return this._itemViews;
    }

    get $containerElem() {
        return this.$elem;
    }

    get delegate() {
        return this._delegate;
    }

    set delegate(newValue) {
        this._delegate = newValue;
    }

    constructor(elem, delegate, itemController) {
        super(elem);

        this.delegate = delegate;
        this._itemController = itemController;
    }

    subscribeTo(list) {
        this._addItemSubscription = list.addItemEvent.subscribe(this.onItemAdded.bind(this));
        this._removeItemSubscription = list.removeItemEvent.subscribe(this.onItemRemoved.bind(this));
        this._moveItemSubscription = list.moveItemEvent.subscribe(this.onItemMoved.bind(this));

        for (var i = 0; i < list.length; i++) {
            this.onItemAdded(list, list.items[i], i);
        }
    }

    unsubscribeFrom(list) {
        this._addItemSubscription.unsubscribe();
        this._removeItemSubscription.unsubscribe();
        this._moveItemSubscription.unsubscribe();

        for (var i = list.length - 1; i >= 0; i--) {
            this.onItemRemoved(list, list.items[i], i);
        }
    }

    onItemAdded(list, item, index) {
        if (this.delegate == null) {
            Logger.warn("[ListView] Delegate not set, cannot add item view.");
            return;
        }

        var view = this.delegate.itemViewForList(list, item);

        if (view == null) {
            Logger.warn("[ListView] Delegate method 'itemViewForList' did not return a view, cannot add item view.");
            return;
        }

        if (index > 0) {
            this._itemViews[index - 1].$elem.after(view.$elem);
        } else {
            this.$containerElem.prepend(view.$elem);
        }

        this._itemViews.splice(index, 0, view);
    }

    onItemRemoved(list, item, index) {
        var view = this._itemViews.splice(index, 1)[0];

        if (view == null) {
            Logger.warn(`[ListView] View at index ${index} is null. Cannot remove item view.`);
            return;
        }

        view.unsubscribeFrom(item);
        if (this._itemController != null) {
            this._itemController.unsubscribeFrom(view);
        }

        view.$elem.detach();
    }

    onItemMoved(list, item, from, to) {
        var itemView = this._itemViews.splice(from, 1)[0];
        this._itemViews.splice(to, 0, itemView);
        if (to > 0) {
            this._itemViews[to - 1].$elem.after(itemView.$elem);
        } else {
            this.$containerElem.prepend(itemView.$elem);
        }
    }
}
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'normalize.css';

import 'css/common.css';
import 'css/configuration-basket.css';

import EQCSS from 'eqcss/EQCSS';
import { IsMobileSafari } from 'van-beek-framework/core/Utilities';

//Ugly VelocityJS fix (http://velocityjs.org/#dependencies, under "Module Loader: Browserify")
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import ModuleLoader from 'core/ModuleLoader';
import { ApplicationBootstrapper } from 'van-beek-framework/core';
//import { RequestRecorder } from 'van-beek-framework/request-recorder/RequestRecorder';

window.configuratorConfig = APP_CONFIG;

var bootstrapper = new ApplicationBootstrapper();

window.onload = function() {
    // window.requestRecorder = new RequestRecorder();
    // $("body").append(window.requestRecorder.$elem);

    bootstrapper.bootstrap(APP_CONFIG, function() {
        if (window.DEBUG_MODE) {
            let liveReloadScriptLocation = 'http://' + (location.host || 'localhost').split(':')[0] + ':35729/livereload.js?snipver=1';
            $.ajax({
                url: liveReloadScriptLocation,
                dataType: 'script',
                async: true
            });
        }

        ModuleLoader.bootstrap(APP_CONFIG);
        EQCSS.apply();
    });

    if (IsMobileSafari()) {
        $('body').on("click", "a", function(event) {
            event.preventDefault();
            window.location = $(this).attr("href");
        });
    }
};

export default APP_CONFIG;
import { EventEmitter } from '../../events';

export default class List {
    addItemEvent = new EventEmitter(this);
    removeItemEvent = new EventEmitter(this);
    moveItemEvent = new EventEmitter(this);

    _items = [];
    _isUnique = false;

    get length() {
        return this._items.length;
    }

    get isUnique() {
        return this._isUnique;
    }

    get items() {
        return this._items;
    }

    set items(newItems) {
        this.clear();

        if (newItems == null) {
            return;
        }

        for (var item of newItems) {
            this.push(item);
        }
    }

    constructor(array, isUnique) {
        this._items = array || [];
        this._isUnique = isUnique || false;
    }

    _validateUnique(item) {
        if (this.isUnique && this.hasItem(item)) {
            throw Error("[List] Attempted to add a duplicate item in a unique list!");
        }
    }

    _validateIndex(index) {
        if (index >= this.length || index < 0) {
            throw Error("[List] Attempted to use index out of bounds! (index: " + index + ", length: " + length + ")");
        }
    }

    push(item) {
        this._validateUnique(item);
        this._items.push(item);
        this.addItemEvent.notify(item, this.length - 1);
    }

    insert(item, index) {
        this._validateUnique(item);
        this._validateIndex(index);
        this._items.splice(index, 0, item);
        this.addItemEvent.notify(item, index);
    }

    remove(item) {
        var index = this.indexOf(item);
        if (index <= -1) {
            return false;
        }

        this._items.splice(index, 1);
        this.removeItemEvent.notify(item, index);

        return true;
    }

    indexOf(item) {
        return this._items.indexOf(item);
    }

    hasItem(item) {
        return this.indexOf(item) >= 0;
    }

    splice(index, amount) {
        this._validateIndex(index);
        var spliced = this._items.splice(index, amount);

        for (var i = spliced.length - 1; i >= 0; i--) {
            this.removeItemEvent.notify(spliced[i], index + i);
        }

        return spliced;
    }

    clear() {
        if (this.length <= 0) {
            return;
        }

        this.splice(0, this.length);
    }

    filter(filterFunc) {
        if (filterFunc == null) {
            return null;
        }

        for (var item of this.items) {
            if (filterFunc(item)) {
                return item;
            }
        }

        return null;
    }
}
import BasePopup from './BasePopup';
import TextInputPopup from './TextInputPopup';

export default class PromptPopup extends TextInputPopup {
    static show(opts) {
        return new PromptPopup(
            opts.title,
            opts.message,
            opts.placeholder,
            opts.value,
            opts.confirmButton,
            opts.cancelButton,
            opts
        ).show();
    }

    get $prompt() {
        return this.$input;
    }

    _bindEventsOnShow() {
        super._bindEventsOnShow();

        this.$prompt.on('keydown', (e) => {
            if (e.which === 13) {
                this._resolve();
            }
        });
    }

    _unbindEventsOnHide() {
        super._unbindEventsOnHide();

        this.$prompt.off('keydown');
    }

    _populate() {
        super._populate();
        this.$input = $(`<input type="text" class="${BasePopup._BaseClassName}__input"></input`);
        this.$content.append(this.$input);
    }

    _popupInteractable() {
        super._popupInteractable();
        this.$prompt.trigger('select');
    }
}
import BasePopup from './BasePopup';
import PopupButton from './PopupButton';

export default class ConfirmPopup extends BasePopup {
    static show(opts) {
        if (opts == null) opts = {};

        return new ConfirmPopup(
            opts.title,
            opts.message,
            opts.icon,
            opts.confirmButton,
            opts.declineButton,
            opts
        ).show();
    }

    constructor(title, message, icon, confirmButton = PopupButton.confirm(), declineButton = PopupButton.cancel(), opts) {
        if (confirmButton.title.length <= 0) {
            confirmButton.title = "popup-defaults.button.accept-nondestructive".localized;
        }

        if (declineButton.title.length <= 0) {
            declineButton.title = "popup-defaults.button.decline".localized;
        }

        super(title, message, icon, [ declineButton, confirmButton ], opts);
        this._confirmButton = confirmButton;
    }

    _resolve(data, hide = true) {
        data = Object.assign({}, data, { value: data != null && data.source === this._confirmButton });
        return super._resolve(data, hide);
    }
    
    _popupInteractable() {
        super._popupInteractable();
        this._confirmButton.focus();
    }
}
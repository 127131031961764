import $ from 'jquery';
import 'jquery-ui/ui/tabbable';

import './BasePopup.css';
import { BaseView } from 'van-beek-framework/abstract';

const TabDirection = Object.freeze({
    forwards: 1,
    backwards: -1
});

const RejectReasons = Object.freeze({
    cancelled: "popup.cancel"
});

export default class BasePopup extends BaseView {
    static RejectReasons = RejectReasons;
    static _BaseClassName = 'vbf-popup';
    static _ActiveClassName = 'vbf-active';

    _buttonSubscriptions = {};
    _active = false;
    _title = "";
    _message = "";
    _icon = null;
    _buttons = [];
    _wrapperInteractionDismisses = true;
    _wrapperInteractionBound = false;

    get wrapperInteractionDismisses() {
        return this._wrapperInteractionDismisses;
    }

    set wrapperInteractionDismisses(newValue) {
        if (newValue === this.wrapperInteractionDismisses) {
            return;
        }

        this._wrapperInteractionDismisses = newValue;

        if (this.wrapperInteractionDismisses) {
            this._bindWrapperInteractionEvents();
        } else {
            this._unbindWrapperInteractionEvents();
        }
    }

    get _resolveDataTemplate() {
        return {
            source: null,
            value: null
        };
    }

    get _rejectDataTemplate() {
        return {
            reason: RejectReasons.cancelled,
            source: null,
            value: null
        };
    }

    get isActive() {
        return this._active;
    }

    get title() {
        return this._title;
    }

    set title(newValue) {
        if (newValue === this._title) {
            return;
        }

        this._title = newValue;
        this.$title.text(this._title);
        this._setElementVisibility(this.$header, !(this._title == null || this._title.length <= 0));
    }

    get message() {
        return this._message;
    }

    set message(newValue) {
        if (newValue === this._message) {
            return;
        }

        this._message = newValue;
        this.$message.text(this._message);
        this._setElementVisibility(this.$message, !(this._message == null || this._message.length <= 0));
    }

    get icon() {
        return this._icon;
    }

    set icon(newValue) {
        if (newValue === this._icon) {
            return;
        }

        this._icon = newValue;
    }

    get buttons() {
        return this._buttons;
    }

    set buttons(newValue) {
        if (newValue === this._buttons) {
            return;
        }

        for (let button in this._buttonSubscriptions) {
            this._buttonSubscriptions[button].unsubscribe();
            delete this._buttonSubscriptions[button];
        }

        this._buttons = newValue;
        this._setElementVisibility(this.$footer, !(this._buttons == null || this._buttons.length <= 0));

        for (let button of this._buttons) {
            this._buttonSubscriptions[button] = button.onSelect.subscribe(this._onButtonSelected.bind(this));
        }
    }

    _onButtonSelected(button) {
        if (!button.cancels) {
            //this._resolve(button);

            this._resolve({
                source: button
            });
        } else {
            //this._reject(RejectReasons.cancelled);

            this._reject({
                source: button,
                reason: RejectReasons.cancelled
            });
        }
    }

    constructor(title, message, icon, buttons, opts) {
        super($(`<div class="${BasePopup._BaseClassName}__wrapper"></div>`));

        // if (this.constructor.name === BasePopup.name) {
        //     throw new Error("BasePopup is an abstract class and should not be initialized directly");
        // }

        this._onKeyDownBind = this._onKeyDown.bind(this);

        if (title != null) this.title = title;
        if (message != null) this.message = message;
        if (icon != null) this.icon = icon;
        if (buttons != null) this.buttons = buttons;

        this._handleOptions(opts);
        this._bindEvents();
    }

    show() {
        if (this.isActive) {
            return;
        }

        this._active = true;

        if (this._hideTimeout != null) {
            clearTimeout(this._hideTimeout);
            this._hideTimeout = null;
        }

        if (this.icon != null) {
            this.$content.prepend(this.icon.$elem);
        }

        this._populateFooter();
        this.$wrapper.appendTo($('body'));
        this._bindEventsOnShow();

        this._showTimeout = setTimeout(() => {
            this.$wrapper.addClass(BasePopup._ActiveClassName);
            this.$popup.addClass(BasePopup._ActiveClassName);

            // Delays are because the above class changes require a small delay to work
            this._showTimeout = setTimeout(() => {
                this.$elem.trigger('focus');
                this._popupInteractable();
            }, 50);
        }, 50);

        let promise = new Promise((resolve, reject) => {
            this._resolveFunction = data => {
                this._resolveFunction = null;
                this._rejectFunction = null;
                resolve(data);
            };

            this._rejectFunction = reason => {
                this._resolveFunction = null;
                this._rejectFunction = null;
                reject(reason);
            };
        });

        return promise;
    }

    hide() {
        if (!this.isActive) {
            return;
        }

        this._active = false;

        if (this._showTimeout != null) {
            clearTimeout(this._showTimeout);
            this._showTimeout = null;
        }

        this._unbindEventsOnHide();
        this.$wrapper.removeClass(BasePopup._ActiveClassName);

        this._hideTimeout = setTimeout(() => {
            this.$popup.removeClass(BasePopup._ActiveClassName);

            this._hideTimeout = setTimeout(() => {
                this._hideTimeout = null;
                this.$wrapper.detach();
                
                if (this.icon != null) {
                    this.icon.$elem.detach();
                }

                this._popupHidden();
            }, 200);
        });


        if (this._rejectFunction != null) {
            this._reject({ reason: RejectReasons.cancelled }, false);
        }
    }

    _handleOptions(opts = {}) {
        for (let option in opts) {
            switch(option) {
                case "wrapperInteractionDismisses":
                    this.wrapperInteractionDismisses = opts[option];
                    break;
                default:
                    break;
            }
        }
    }

    _populate() {
        this.$wrapper = this.$elem;
        this.$popup = $(`<div class="${BasePopup._BaseClassName}"></div>`);

        this.$header = $(`<div class="${BasePopup._BaseClassName}__header"></div>`);
        this.$title = $(`<div class="${BasePopup._BaseClassName}__title"></div>`);
        this.$header.append(this.$title);
        this.$popup.append(this.$header);

        this.$content = $(`<div class="${BasePopup._BaseClassName}__content"></div>`);
        this.$message = $(`<div class="${BasePopup._BaseClassName}__message"></div>`);
        this.$content.append(this.$message);
        this.$popup.append(this.$content);

        this.$footer = $(`<div class="${BasePopup._BaseClassName}__footer"></div>`);
        this.$popup.append(this.$footer);

        this.$wrapper.append(this.$popup);
    }

    _setElementVisibility($element, setVisible) {
        let currentlyVisible = $element.hasClass(BasePopup._ActiveClassName);
        if (setVisible === currentlyVisible) {
            return;
        }

        if (setVisible) {
            $element.addClass(BasePopup._ActiveClassName);
        } else {
            $element.removeClass(BasePopup._ActiveClassName);
        }
    }

    _bindEvents() {
        if (this.wrapperInteractionDismisses) {
            this._bindWrapperInteractionEvents();
        }
    }

    _bindWrapperInteractionEvents() {
        if (this._wrapperInteractionBound) {
            return;
        }

        this._wrapperInteractionBound = true;
        this.$wrapper.on("click", this._handleWrapperClick.bind(this));
    }

    _unbindWrapperInteractionEvents() {
        if (!this._wrapperInteractionBound) {
            return;
        }

        this._wrapperInteractionBound = false;
        this.$wrapper.off("click");
    }

    _handleWrapperClick(e) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

        if ($(e.target).hasClass(`${BasePopup._BaseClassName}__wrapper`)) {
            this._reject();
        }
    }

    _bindEventsOnShow() {
        $(document).on("keydown", this._onKeyDownBind);
    }

    _unbindEventsOnHide() {
        $(document).on("keydown", this._onKeyDownBind);
    }

    _onKeyDown(e) {
        const keyCodes = {
            escape: 27,
            enter: 13,
            tab: 9
        };

        if (e.keyCode === keyCodes.escape) {
            this._reject();
            return;
        }

        if (e.keyCode === keyCodes.enter) {
            let $target = $(e.target);
            if ($target.hasClass(`${BasePopup._BaseClassName}__button`)) {
                $target.trigger('click');
            }
            
            return;
        }

        if (e.keyCode === keyCodes.tab) {
            e.preventDefault();
            e.stopPropagation();

            this._focusInput(e.shiftKey ? TabDirection.backwards : TabDirection.forwards);
        }
    }

    _focusInput(direction = TabDirection.forwards) {
        let $focusedElement = this.$elem.find(":focus");
        let $tabbableElements = this.$elem.find(":tabbable");

        if ($tabbableElements.length <= 0) {
            return;
        }

        if ($focusedElement.length <= 0) {
            $tabbableElements.first().trigger('focus');
            return;
        }

        let newFocusedIndex = $tabbableElements.index($focusedElement) + direction;

        if (newFocusedIndex >= $tabbableElements.length) {
            newFocusedIndex = 0;
        } else if (newFocusedIndex < 0) {
            newFocusedIndex = $tabbableElements.length - 1;
        }

        let $newFocusedElement = $($tabbableElements.get(newFocusedIndex));
        if ($newFocusedElement.length > 0) {
            $newFocusedElement.trigger('focus');
        } else {
            $tabbableElements.first().trigger('focus');
        }
    }

    _resolve(data, hide = true) {
        data = Object.assign({}, this._resolveDataTemplate, data);

        if (this._resolveFunction != null) {
            this._resolveFunction(data);
        }

        if (hide) {
            this.hide();
        }
    }

    _reject(data, hide = true) {
        data = Object.assign({}, this._rejectDataTemplate, data);

        if (this._rejectFunction != null) {
            this._rejectFunction(data);
        }

        if (hide) {
            this.hide();
        }
    }

    _populateFooter() {
        this.$footer.empty();

        for (let button of this.buttons) {
            this.$footer.append(button.$elem);
        }
    }

    _popupInteractable() {
        this._focusInput();
    }

    _popupHidden() {}
}
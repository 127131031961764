import { BaseView } from 'van-beek-framework/abstract';

export default class ConfigurationActionMenu extends BaseView {

    constructor() {
        super($('<div class="configuration-basket__configuration__action-menu__wrapper"></div>'));

        this.$backgroundElem = $('<div class="configuration-basket__configuration__action-menu__background"></div>');

        this.$menuElem = $('<div class="configuration-basket__configuration__action-menu"></div>');
        this.$primaryActions = $('<div class="configuration-basket__configuration__action-menu__list" data-primary></div>');
        this.$secondaryActions = $('<div class="configuration-basket__configuration__action-menu__list"></div>');

        this.$cancelAction = $(`<a class="configuration-basket__configuration__action" href="javascript:void(0);" data-action="cancel"><i class="fa fa-fw fa-times"></i> ${"item.action-menu-cancel".localized}</a>`);

        this.$secondaryActions.append(this.$cancelAction);
        this.$menuElem.append(this.$primaryActions);
        this.$menuElem.append(this.$secondaryActions);

        this.$elem.append(this.$backgroundElem);
        this.$elem.append(this.$menuElem);

        this._active = false;
        this._hideAnimationTimeout = null;
    }

    get isActive() {
        return this._active;
    }

    _populate() {
        this.$spacer = $('<div class="configuration-basket__configuration__action-menu__spacer"></div>');

        this._actions = {
            rename: this.$renameAction,
            comment: this.$commentAction,
            delete: this.$deleteAction,
            duplicate: this.$duplicateAction
        };
    }

    _setupEvents() {
        $(document).on('click touch', (e) => {
            e.stopPropagation();
            this.hide();
        });

        $(window).on('resize', (e) => {
            this.hide(false);
        });

        $(window).on('blur', () => {
            this.hide();
        });
    }

    hide(animate = true) {
        if (!this.isActive) {
            return false;
        }

        this._active = false;

        if (animate) {
            this.$elem.removeClass("active");
            this._$menuButtonElem.removeClass("active");

            this._hideAnimationTimeout = setTimeout(this._cleanup.bind(this), 300);
        } else {
            this._cleanup();
        }

        return true;
    }

    _cleanup() {
        for (var $menuActionElem of this._menuActionElems) {
            $menuActionElem.detach();
        }
        this._menuActionElems = null;

        this.$elem.detach();
        this.$elem.removeClass("active");
        this.$primaryActions.html('');

        this._$menuButtonElem.removeClass("active");
        this._$menuButtonElem = null;

        this._hideAnimationTimeout = null;
    }

    show(view, $menuButtonElem, menuActionElems) {
        if (this.isActive && $menuButtonElem === this._$menuButtonElem) {
            this.hide();
            return;
        }

        if (this.isActive || this._hideAnimationTimeout != null) {
            clearTimeout(this._hideAnimationTimeout);
            this._cleanup();
        }

        this._$menuButtonElem = $menuButtonElem;
        this._menuActionElems = menuActionElems;

        this._active = true;
        this.$elem.appendTo("body");
        this._updatePosition();
        this.$elem.addClass("active");
        this._$menuButtonElem.addClass("active");

        for (var i = 0; i < menuActionElems.length; i++) {
            if (i > 0) {
                this.$primaryActions.append(this.$spacer.clone());
            }

            this.$primaryActions.append(menuActionElems[i]);
        }
    }

    _updatePosition() {
        if (!this.isActive) {
            return;
        }

        var viewOffsetTop = this._$menuButtonElem.offset().top + this._$menuButtonElem.outerHeight();
        var viewOffsetLeft = this._$menuButtonElem.offset().left;
        var viewWidth = this._$menuButtonElem[0].getBoundingClientRect().width;

        this.$elem.css("top", viewOffsetTop + "px");
        this.$elem.css("left", viewOffsetLeft + "px");
        this.$elem.css("width", viewWidth + "px");
    }
}
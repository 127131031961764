import './TextInputPopup.css';
import BasePopup from './BasePopup';
import PopupButton from './PopupButton';

export default class TextInputPopup extends BasePopup {
    _valueChangeDisablesWrapperInteraction = true;
    _initialValue = "";
    
    get valueChangeDisablesWrapperInteraction() {
        return this._valueChangeDisablesWrapperInteraction;
    }

    set valueChangeDisablesWrapperInteraction(newValue) {
        if (newValue === this.valueChangeDisablesWrapperInteraction) {
            return;
        }

        this._valueChangeDisablesWrapperInteraction = newValue;
    }

    constructor(title, message, placeholder, value = null, confirmButton = PopupButton.neutral(), cancelButton = PopupButton.cancel(), opts) {
        if (confirmButton.title.length <= 0) {
            confirmButton.title = "popup-defaults.button.accept-nondestructive".localized;
        }

        if (cancelButton.title.length <= 0) {
            cancelButton.title = "popup-defaults.button.cancel".localized
        }

        super(title, message, null, [ cancelButton, confirmButton ], opts);

        // if (this.constructor.name === TextInputPopup.name) {
        //     throw new Error("TextInputPopup is an abstract class and should not be initialized directly");
        // }

        if (placeholder != null) {
            this.$prompt.attr('placeholder', placeholder);
        }

        if (value != null) {
            this.$prompt.val(value);
        }
    }

    get $prompt() {
        throw new Error("Getter $prompt is abstract and should be overridden");
    }

    show() {
        this._initialValue = this.$prompt.val();
        return super.show();
    }

    hide() {
        this.$prompt.trigger('blur');
        return super.hide();
    }

    _handleOptions(opts = {}) {
        super._handleOptions(opts);

        for (let option in opts) {
            switch(option) {
                case "valueChangeDisablesWrapperInteraction":
                    this.valueChangeDisablesWrapperInteraction = opts[option];
                    break;
                default:
                    break;
            }
        }
    }

    _bindEvents() {
        super._bindEvents();

        this.$prompt.on('input', () => {
            if (!this.valueChangeDisablesWrapperInteraction) {
                return;
            }

            let newValue = this.$prompt.val();
            if (newValue === this._initialValue) {
                this._bindWrapperInteractionEvents();
            } else {
                this._unbindWrapperInteractionEvents();
            }
        });
    }

    _populate() {
        super._populate();
        this.$popup.addClass("vbf-popup--prompt");
    }

    _resolve(data, hide = true) {
        data = Object.assign({}, data, { value: this.$prompt.val().trim() })
        return super._resolve(data, hide);
    }

    _reject(data, hide = true) {
        data = Object.assign({}, data, { value: this.$prompt.val().trim() });
        return super._reject(data, hide);
    }

    _popupInteractable() {
        super._popupInteractable();
        this.$prompt.trigger('focus');
    }

    _popupHidden() {
        super._popupHidden();
        this.$prompt.val('');
    }
}
import $ from 'jquery';

import './PopupButton.css';
import { BaseView } from 'van-beek-framework/abstract';
import { EventEmitter } from 'van-beek-framework/events';
import BasePopup from './BasePopup';

export default class PopupButton extends BaseView {
    onSelect = new EventEmitter(this);

    _disabled = false;
    _title = "";
    _cancels = false;

    get title() {
        return this._title != null ? this._title : "";
    }

    set title(newValue) {
        if (newValue === this._title) {
            return;
        }

        this._title = newValue;
        this.$elem.text(this._title);
    }

    get cancels() {
        return this._cancels != null ? this._cancels : false;
    }

    set cancels(newValue) {
        if (newValue === this._cancels) {
            return;
        }

        this._cancels = newValue;
    }

    get isDisabled() {
        return this._disabled != null ? this._disabled : false;
    }

    get type() {
        return this._type != null ? this._type : "";
    }

    set type(newValue) {
        if (newValue === this.type) {
            return;
        }

        this._type = newValue;
        
        if (this.type != null) {
            this.$elem.attr("data-type", this._type);
        } else {
            this.$elem.removeAttr("data-type");
        }
    }

    static positive(title) {
        return new PopupButton(title, "positive");
    }

    static destructive(title) {
        return new PopupButton(title, "destructive");
    }

    static neutral(title) {
        return new PopupButton(title);
    }

    static secondary(title, cancels = false) {
        return new PopupButton(title, "secondary", cancels);
    }

    static confirm(title = "popup-defaults.button.accept-nondestructive".localized) {
        return PopupButton.positive(title);
    }

    static cancel(title = "popup-defaults.button.cancel".localized) {
        return PopupButton.secondary(title, true);
    }

    constructor(title, type, cancels) {
        super($(`<div class="${BasePopup._BaseClassName}__button" tabindex="1"></div>`));
        this.title = title;
        this.type = type;
        this.cancels = cancels;
    }

    select() {
        if (this.isDisabled) {
            return;
        }

        this.onSelect.notify();
    }

    focus() {
        this.$elem.trigger('focus');
    }

    blur() {
        this.$elem.trigger('blur');
    }

    _setupEvents() {
        this.$elem.on('click', this.select.bind(this));
    }
}
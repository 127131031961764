import List from './List'

export default class SortedList extends List {

    _comparator = null;

    set comparator(compareFunc) {
        this._comparator = compareFunc;
        this.sort();
    }

    get comparator() {
        return this._comparator;
    }

    constructor(array, comparator) {
        super(array, true);
        this.comparator = comparator;
    }

    push(item) {
        if (this.comparator == null) {
            super.push(item);
            return;
        }

        this._validateUnique(item);

        var index = 0;

        for (; index < this.length; index++) {
            var order = this.comparator(item, this.items[index]);
            if (order <= 0) {
                break;
            }
        }

        if (index > 0 && index > this.length) {
            index = this.length;
        }

        this.items.splice(index, 0, item);
        this.addItemEvent.notify(item, index);
    }

    insert(item, index) {
        console.warn("[SortedList] Inserting at index is not supported in a SortedList. Pushing item at sorted location instead.");
        this.push(item);
    }

    sort() {
        if (this.comparator == null) {
            return;
        }

        var newSort = this.items.concat().sort(this.comparator);

        for (var i = 0; i < newSort.length; i++) {

            if (newSort[i] !== this.items[i]) {
                var index = this.items.indexOf(newSort[i]);
                this.items.splice(i, 0, this.items.splice(index, 1)[0]);
                this.moveItemEvent.notify(this.items[i], index, i);
            }
        }
    }
}
import AlertPopup from './AlertPopup';
import PopupIcon from './PopupIcon';
import PopupButton from './PopupButton';

export default class ErrorPopup extends AlertPopup {
    static show(opts) {
        if (opts == null) opts = {};

        return new ErrorPopup(
            opts.title,
            opts.message,
            opts
        ).show();
    }

    constructor(title = "generic.popup-error.title".localized, message = "generic.popup-error.message".localized, opts) {
        super(title, message, PopupIcon.error(), PopupButton.neutral(), opts);
    }
}
import BasePopup from './BasePopup';
import TextInputPopup from './TextInputPopup';

export default class MultiLinePromptPopup extends TextInputPopup {
    static show(opts) {
        return new MultiLinePromptPopup(
            opts.title,
            opts.message,
            opts.placeholder,
            opts.value,
            opts.confirmButton,
            opts.cancelButton,
            opts
        ).show();
    }

    get $prompt() {
        return this.$textArea;
    }

    show() {
        let promise = super.show();

        this.$prompt.css({
            'height': (this.$prompt.scrollHeight) + 'px',
            'overflow-y': 'hidden'
        });

        this._updatePromptHeight();

        return promise;
    }

    _updatePromptHeight() {
        var elem = this.$prompt[0];
        elem.style.height = 'auto';
        elem.style.height = (elem.scrollHeight) + 'px';
    }

    _bindEventsOnShow() {
        super._bindEventsOnShow();

        this.$prompt.on('keydown', (e) => {
            if (e.which === 13 && e.shiftKey) {
                this._resolve();
            }
        });
        
        this.$prompt.on('input', this._updatePromptHeight.bind(this));
    }

    _unbindEventsOnHide() {
        super._unbindEventsOnHide();
        this.$prompt.off('keydown input');
    }

    _populate() {
        super._populate();
        this.$textArea = $(`<textarea class="${BasePopup._BaseClassName}__input"></textarea`);
        this.$content.append(this.$textArea);

        this.$popup.addClass(`${BasePopup._BaseClassName}--textarea-prompt`);
    }
}
import { BaseView } from 'van-beek-framework/abstract';
import { EventEmitter } from 'van-beek-framework/events';
import { OnResize } from 'van-beek-framework/core/Utilities';
import { PionusDocumentCache, PionusDocument, DocumentNotFoundException } from 'van-beek-framework/networking';

export default class ProjectItemView extends BaseView {
    _id = null;
    _name = null;
    _quantity = 1;
    _modelDocumentId = 0;
    _modelOutdated = false;
    _modelGenerated = false;

    commentTruncateButtonEvent = new EventEmitter(this);
    showModelButtonEvent = new EventEmitter(this);

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    constructor() {
        super($('<div class="configuration-basket__configuration"></div>'));
    }

    _populate() {
        this.$leftContentWrapper = $('<div class="configuration-basket__configuration__left-wrapper"></div>');
        this.$textWrapper = $('<div class="configuration-basket__configuration__text-wrapper"></div>');
        this.$title = $('<div class="configuration-basket__configuration__title"></div>');
        this.$comment = $('<div class="configuration-basket__configuration__comment"></div>');

        this.$commentTruncateButton = $(`<div class="truncate-button">${"item.comment-read-more".localized}</div>`);
        this.$comment.append(this.$commentTruncateButton);
        this._isTruncated = true;
        this._truncationButtonShown = false;
        this.setTruncation(true);

        this.$textWrapper.append(this.$title);
        this.$textWrapper.append(this.$comment);

        this.$preview = $('<div class="configuration-basket__configuration__preview"></div>');
        this.$previewImage = $('<img class="configuration-basket__configuration__preview__image" />');
        this.$previewOverlay = $('<div class="configuration-basket__configuration__preview__overlay"></div>')
        this.$previewIconGenerating = $('<div class="configuration-basket__configuration__preview__icon configuration-basket__configuration__preview__icon-generating"><i class="fas fa-cog fa-spin" /></div>');
        this.$previewIconGeneratingSmall = $('<div class="configuration-basket__configuration__preview__icon configuration-basket__configuration__preview__icon-small"><i class="fas fa-cog" /></div>');

        this.$preview.append(this.$previewImage);
        this.$preview.append(this.$previewOverlay);
        this.$preview.append(this.$previewIconGenerating);
        this.$preview.append(this.$previewIconGeneratingSmall);

        this.$leftContentWrapper.append(this.$preview);
        this.$leftContentWrapper.append(this.$textWrapper);

        this.$rightContentWrapper = $('<div class="configuration-basket__configuration__right-wrapper"></div>');

        this.$amountInputWrapper = $('<div class="configuration-basket__configuration__amount"></div>');
        this.$amountStatic = $('<div class="configuration-basket__configuration__amount-static"></div>');
        this.$amountInputWrapper.append(this.$amountStatic);

        this.$propertyWrapper = $('<div class="configuration-basket__configuration__properties"></div>');
        this.$price = $('<div class="configuration-basket__configuration__price"></div>');
        this.$propertyWrapper.append(this.$price);

        this.$rightContentWrapper.append(this.$amountInputWrapper);
        this.$rightContentWrapper.append(this.$propertyWrapper);

        this.$actionWrapper = $('<div class="configuration-basket__configuration__actions"></div>');
        this.$showModelAction = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__action" data-action="show-model"><i class="fa fa-fw fa-cube"></i>${"item.action-show-model.button".localized}</a>`);
        this._actionElems =  [this.$showModelAction];

        this.$elem.append(this.$leftContentWrapper);
        this.$elem.append(this.$actionWrapper);
        this.$elem.append(this.$rightContentWrapper);
    }

    _setupEvents() {
        this.$commentTruncateButton.on('click', () => {
            this.commentTruncateButtonEvent.notify();
        });

        this.$showModelAction.on("click", (e) => {
            e.stopPropagation();
            this.showModelButtonEvent.notify(this._modelDocumentId, this._modelOutdated, this._modelGenerated);
        });

        this.$preview.on("click", (e) => {
            this.showModelButtonEvent.notify(this._modelDocumentId, this._modelOutdated, this._modelGenerated);
        });

        OnResize(() => {
            this._updateCommentSize();
        });

        //A hacky workaround to ensure the element height is available when this is called.
        setTimeout(() => { this._updateCommentSize(); }, 0);
    }

    subscribeTo(item) {
        super.subscribeTo(item);

        this._idSubscription = item.idChangedEvent.subscribe(this.onIdChanged.bind(this));
        this._nameSubscription = item.nameChangedEvent.subscribe(this.onNameChanged.bind(this));
        this._commentSubscription = item.commentChangedEvent.subscribe(this.onCommentChanged.bind(this));
        this._priceSubscription = item.priceChangedEvent.subscribe(this.onPriceChanged.bind(this));
        this._modelDocumentSubscription = item.modelDocumentChangedEvent.subscribe(this.onModelDocumentChanged.bind(this));
        this._quantitySubscription = item.quantityChangedEvent.subscribe(this.onQuantityChanged.bind(this));

        this._modelDocumentOutdatedSubscription = item.modelDocumentOutdatedEvent.subscribe(this.onModelDocumentOutdatedChanged.bind(this));

        this.onIdChanged(item);
        this.onNameChanged(item);
        this.onCommentChanged(item);
        this.onPriceChanged(item);
        this.onModelDocumentOutdatedChanged(item);
        this.onModelDocumentChanged(item);
        this.onQuantityChanged(item);
    }

    unsubscribeFrom(item) {
        super.unsubscribeFrom(item);

        this._idSubscription.unsubscribe();
        this._nameSubscription.unsubscribe();
        this._commentSubscription.unsubscribe();
        this._priceSubscription.unsubscribe();
        this._modelDocumentSubscription.unsubscribe();
        this._quantitySubscription.unsubscribe();

        this._modelDocumentOutdatedSubscription.unsubscribe();
    }

    onIdChanged(item) {
        this._id = item.id;
        this.$elem.attr("data-id", item.id);
    }

    onNameChanged(item) {
        this._name = item.name;
        this.$title.text(item.name);
    }

    onCommentChanged(item) {
        this._comment = item.comment;
        this.$commentTruncateButton.detach();
        this._truncationButtonShown = false;
        this.$comment.text(this._comment);

        this._updateCommentSize();
    }

    onPriceChanged(item) {
        this.$price.html(item.formattedTotalPrice);
    }

    onQuantityChanged(item) {
        this._quantity = item.quantity;
        this.$amountStatic.text(item.quantity + "x");
    }

    onModelDocumentChanged(item) {
        this._modelGenerated = item.canExecute;

        if (this._modelDocumentId === item.modelDocumentId) {
            return;
        }

        this._modelDocumentId = item.modelDocumentId;
        this.updateActions(item);

        if (!isNaN(this._modelDocumentId) && this._modelDocumentId > 0) {
            (async() => {
                this._setPreviewGeneratingIcon(true, true);

                try {
                    let imageBlob = await PionusDocumentCache.shared.getBlob(this._modelDocumentId, PionusDocument.jpg);
                    this.$previewImage.attr("src", imageBlob);
                } catch(e) {
                    if (e instanceof DocumentNotFoundException) {
                        this._setPreviewGeneratingIcon(true);
                        return;
                    }
                }

                this._setPreviewGeneratingIcon(item.modelDocumentOutdated);
            })();
        } else {
            this._setPreviewGeneratingIcon(item.canExecute);
        }
    }

    onModelDocumentOutdatedChanged(item) {
        if (this._modelOutdated === item.modelDocumentOutdated) {
            return;
        }

        this._setPreviewGeneratingIcon(item.modelDocumentOutdated);
        this._modelOutdated = item.modelDocumentOutdated;
    }

    _setPreviewGeneratingIcon(isActive, keepVisible = false) {
        if (isActive) {
            this.$previewIconGenerating.addClass("active");
            this.$previewIconGeneratingSmall.addClass("active");
            this.$previewOverlay.addClass("active");
        } else {
            this.$previewIconGenerating.removeClass("active");
            this.$previewIconGeneratingSmall.removeClass("active");
            this.$previewOverlay.removeClass("active");
        }

        if (keepVisible) {
            this.$previewIconGeneratingSmall.removeClass("active");
        }

        if (keepVisible) {
            this.$previewIconGenerating.addClass("keep-visible");
        } else {
            this.$previewIconGenerating.removeClass("keep-visible");
        }
    }

    _updateCommentSize() {
        if (this._comment == null || this._comment.length <= 0) {
            return;
        }
        
        var contentHeight = this.$comment[0].scrollHeight;
        if (contentHeight <= 0) {
            return;
        }

        var resetTruncation = false;
        if (!this._isTruncated) {
            this.setTruncation(true, false);
            resetTruncation = true;
        }

        var containerHeight = Math.ceil(this.$comment.height());
        if (containerHeight >= contentHeight && this._truncationButtonShown == true) {
            this.$commentTruncateButton.detach();
            this._truncationButtonShown = false;
            resetTruncation = false;
        } else if (containerHeight < contentHeight && this._truncationButtonShown == false) {
            this.$comment.append(this.$commentTruncateButton);
            this._truncationButtonShown = true;
        }

        if (resetTruncation) {
            this.setTruncation(false, false);
        }
    }

    setTruncation(shouldTruncate, shouldUpdateCommentSize = true) {
        if (shouldTruncate == null) {
            shouldTruncate = !this._isTruncated;
        }

        if (shouldTruncate) {
            this.$comment.removeClass("expanded");
            this.$leftContentWrapper.removeClass("read-more");
            this.$commentTruncateButton.text("item.comment-read-more".localized);
        } else {
            this.$comment.addClass("expanded");
            this.$leftContentWrapper.addClass("read-more");
            this.$commentTruncateButton.text("item.comment-read-less".localized);
        }

        this._isTruncated = shouldTruncate;

        if (shouldUpdateCommentSize) {
            this._updateCommentSize();
        }
    }

    updateActions(item) {
        let actions = this._getActiveActions(item);
        this._setActiveActions(actions);
    }

    _getActiveActions(item) {
        let actions = [];

        if (item.hasModel) {
            actions.push(this.$showModelAction);
        }

        return actions;
    }

    _setActiveActions(actions) {
        for (var $action of this._actionElems) {
            $action.detach();
        }
        this.$actionWrapper.empty();

        for (var $action of actions) {
            if ($action == null) {
                continue;
            }

            this.$actionWrapper.append($action);
        }
    }
}
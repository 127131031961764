import $ from 'jquery';

import './PopupIcon.css';
import { BaseView } from 'van-beek-framework/abstract';
import BasePopup from './BasePopup';

export default class PopupIcon extends BaseView {
    _style = "";
    _effect = "";
    _color = "";
    _name = ""

    get name() {
        return this._name;
    }

    set name(newValue) {
        if (newValue === this.name) {
            return;
        }

        if (this.name != null) {
            this.$icon.removeClass(`fa-${this.name}`);
        }

        this._name = newValue;
        
        if (this.name != null) {
            this.$icon.addClass(`fa-${this.name}`);
        }
    }

    get color() {
        return this._color;
    }

    set color(newValue) {
        if (newValue === this.color) {
            return;
        }

        this._color = newValue;
        this.$elem.css("color", this.color != null ? this.color : "");
    }

    get style() {
        return this._style;
    }

    set style(newValue) {
        if (newValue === this.style) {
            return;
        }

        if (this.style != null) {
            this.$icon.removeClass(this.style);
        }

        this._style = newValue;
        
        if (this.style != null) {
            this.$icon.addClass(this.style);
        }
    }

    get effect() {
        return this._effect;
    }

    set effect(newValue) {
        if (newValue === this.effect) {
            return;
        }

        if (this.effect != null) {
            this.$icon.removeClass(this.effect);
        }

        this._effect = newValue;
        
        if (this.effect != null) {
            this.$icon.addClass(this.effect);
        }
    }

    static trash(color = "#EA2027") {
        return new PopupIcon("trash-alt", color);
    }

    static loading(color = "#009ee2") {
        return new PopupIcon("cog", color, "fas", "fa-spin");
    }

    static error(color = "#EA2027") {
        return new PopupIcon("times-circle", color);
    }

    static warning(color = "#F79F1F") {
        return new PopupIcon("exclamation-triangle", color);
    }

    constructor(name, color, style = "fas", effect = null) {
        super($(`<div class="${BasePopup._BaseClassName}__icon"></div>`));

        this.name = name;
        this.color = color;
        this.style = style;
        this.effect = effect;
    }

    _populate() {
        super._populate();
        this.$icon = $(`<i></i>`);
        this.$elem.append(this.$icon);
    }
}
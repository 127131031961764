import { ProjectItemController } from 'entities/project-item';
import { ErrorPopup, ConfirmPopup, LoadingPopup, MultiLinePromptPopup, PopupButton, PopupIcon, PromptPopup } from 'van-beek-framework/components/ui/popups';
import ConfiguratorNetworkManager from 'core/ConfiguratorNetworkManager';
import ConfigurationActionMenu from 'entities/configuration/ConfigurationActionMenu';

export default class ConfigurationController extends ProjectItemController {
    static _configurationActionMenu = null;

    static get ConfigurationActionMenu() {
        if (this._configurationActionMenu == null) {
            this._configurationActionMenu = new ConfigurationActionMenu();
        }

        return this._configurationActionMenu;
    }

    subscribeTo(view) {
        super.subscribeTo(view);

        view.renameSubscription = view.renameButtonEvent.subscribe(this.onRenameConfiguration.bind(this));
        view.deleteSubscription = view.deleteButtonEvent.subscribe(this.onDeleteConfiguration.bind(this));
        view.duplicateSubscription = view.duplicateButtonEvent.subscribe(this.onDuplicateConfiguration.bind(this));
        view.quantityChangedSubscription = view.amountInputEvent.subscribe(this.onQuantityChanged.bind(this));
        view.actionMenuSubscription = view.menuButtonEvent.subscribe(this.onShowActionMenu.bind(this));
        view.commentSubscription = view.commentButtonEvent.subscribe(this.onPlaceComment.bind(this));
    }

    unsubscribeFrom(view) {
        super.unsubscribeFrom(view);

        view.renameSubscription.unsubscribe();
        view.deleteSubscription.unsubscribe();
        view.duplicateSubscription.unsubscribe();
        view.permanentDeleteSubscription.unsubscribe();
        view.quantityChangedSubscription.unsubscribe();
        view.actionMenuSubscription.unsubscribe();
        view.commentSubscription.unsubscribe();
    }

    onShowActionMenu(view, $menuButtonElem, menuActionElems) {
        ConfigurationController.ConfigurationActionMenu.show(view, $menuButtonElem, menuActionElems);
    }

    onQuantityChanged(view, quantity) {
        if (window.ConfiguratorManager == null) {
            return;
        }

        let configuration = window.ConfiguratorManager.getConfiguration(view.id);
        let oldQuantity = configuration.quantity;
        configuration.quantity = quantity;

        if (configuration.quantity == quantity) {
            ConfiguratorNetworkManager.updateConfigurationQuantity(view.id, quantity, (success, error) => {
                if (!success) {
                    configuration.quantity = oldQuantity;
                    this._showGenericError(error);
                }
            });
        } else {
            configuration.quantity = configuration.quantity; //Force update to original value
        }
    }

    onDuplicateConfiguration(_, id, uuid, currentName) {
        ConfigurationController.ConfigurationActionMenu.hide();

        PromptPopup.show({
            title: "item.action-duplicate.popup-name-copy.title".localized.fillPlaceholders(currentName),
            message: "item.action-duplicate.popup-name-copy.message".localized,
            placeholder: "item.action-duplicate.popup-name-copy.input-name".localized,
            value: "item.action-duplicate.popup-name-copy.input-name-default".localized.fillPlaceholders(currentName),
            confirmButton: PopupButton.neutral("item.action-duplicate.popup-name-copy.button-confirm".localized)
        }).then(data => {
            if (data.value <= 0) {
                return;
            }

            LoadingPopup.show();

            ConfiguratorNetworkManager.duplicateConfiguration(id, uuid, data.value, (_, error) => {
                LoadingPopup.hide();

                if (error != null) {
                    this._showGenericError(error);
                }
            });
        }).catch(() => {});
    }

    onRenameConfiguration(_, id, uuid, currentName) {
        ConfigurationController.ConfigurationActionMenu.hide();

        PromptPopup.show({
            title: "item.action-rename.popup-new-name.title".localized.fillPlaceholders(currentName),
            //message: "item.action-rename.popup-new-name.message".localized,
            placeholder: "item.action-rename.popup-new-name.input-name".localized,
            value: currentName,
            confirmButton: PopupButton.neutral("item.action-rename.popup-new-name.button-confirm".localized)
        }).then(data => {
            LoadingPopup.show();

            ConfiguratorNetworkManager.renameConfiguration(id, uuid, data.value, (_, error) => {
                LoadingPopup.hide();

                if (error != null) {
                    this._showGenericError(error);
                }
            });
        }).catch(() => {});
    }

    onDeleteConfiguration(view, id) {
        ConfigurationController.ConfigurationActionMenu.hide();

        ConfirmPopup.show({
            title: "item.action-delete.popup-confirm.title".localized.fillPlaceholders(view.name),
            message: "item.action-delete.popup-confirm.message".localized.fillPlaceholders(view.name),
            icon: PopupIcon.trash(),
            confirmButton: PopupButton.destructive("item.action-delete.popup-confirm.button-confirm".localized)
        }).then(() => {
            LoadingPopup.show();

            ConfiguratorNetworkManager.deleteConfiguration(id, (_, error) => {
                LoadingPopup.hide();

                if (error != null) {
                    this._showGenericError(error);
                }
            });
        }).catch(() => {});
    }

    onPlaceComment(view, id, uuid, currentComment) {
        ConfigurationController.ConfigurationActionMenu.hide();

        MultiLinePromptPopup.show({
            title: "item.action-comment.popup-comment.title".localized,
            message: "item.action-comment.popup-comment.message".localized.fillPlaceholders(view.name),
            placeholder: "item.action-comment.popup-comment.input-comment".localized,
            value: currentComment,
            confirmButton: PopupButton.neutral("item.action-comment.popup-comment.button-confirm".localized)
        }).then(data => {
            LoadingPopup.show();

            ConfiguratorNetworkManager.updateConfigurationComment(id, uuid, data.value, success => {
                LoadingPopup.hide();

                if (success) {
                    return;
                }

                ErrorPopup.show({
                    title: "item.action-comment.popup-error.title".localized,
                    message: "item.action-comment.popup-error.message".localized
                }).then(() => {
                    this.onPlaceComment(view, id, uuid, data.value);
                });
            });
        }).catch(() => {});
    }

    onShowModel(view, modelDocumentId, modelOutdated, modelGenerated) {
        ConfigurationController.ConfigurationActionMenu.hide();
        super.onShowModel(view, modelDocumentId, modelOutdated, modelGenerated);
    }

}
import BasePopup from './BasePopup';
import PopupButton from './PopupButton';

export default class AlertPopup extends BasePopup {
    static show(opts) {
        if (opts == null) opts = {};

        return new AlertPopup(
            opts.title,
            opts.message,
            opts.icon,
            opts.confirmButton,
            opts
        ).show();
    }

    constructor(title, message, icon, confirmButton = PopupButton.neutral(), opts) {
        if (confirmButton.title.length <= 0) {
            confirmButton.title = "popup-defaults.button.acknowledge".localized;
        }

        super(title, message, icon, [ confirmButton ], opts);
    }

    show() {
        let promise = super.show();
        this._rejectFunction = this._resolveFunction;
        return promise;
    }
    
    hide() {
        if (this._resolveFunction != null) {
            this._resolveFunction();
        }

        super.hide();
    }

    _reject() {
        this.hide();
    }
}